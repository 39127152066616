import type { InjectionKey } from 'vue'

// repository
import { useRepositoryFactory } from '@/composables/repository/useRepositoryFactory'
import {
  getAchievementsResponse,
  GetAchievementDetailRequest,
  getAchievementDetailResponse,
  PatchAchievementDetailRequest,
  patchAchievementDetailResponse,
  PatchOtherAchievementsDetailResponse,
  PostGrantAchievementRequest,
  postGrantAchievementResponse,
} from '@/composables/repository/useAchievementRepository'

import { isValueOf } from '@/utils/zod'

// models
import {
  UserAchievement,
  AchievementDetail,
  SortType,
  SortData,
  AccomplishedUser,
  SORT_TYPE,
  Achievement,
} from '@/models/achievement'

export const useAchievement = () => {
  const repositoryFactory = useRepositoryFactory()
  const achievementRepository = repositoryFactory.get('achievement')
  const i18n = useI18n()

  type AchievementStateType = {
    maxShowingBadgeCount: number
    achievements: UserAchievement[]
    // アチーブメントの所持数
    achievementCount: number
    totalAchievementCount: number
    achievementDetail: AchievementDetail | null
    otherAchievementList: UserAchievement[]
    accomplishedUsers: AccomplishedUser[]
    accomplishedUsersCount: number
    accomplishedUsersLimit: number
    grantAchievement: Achievement | null
  }

  const state = useState<AchievementStateType>('achievement_state', () => ({
    maxShowingBadgeCount: 3,
    achievements: [],
    achievementCount: 0,
    totalAchievementCount: 0,
    achievementDetail: null,
    otherAchievementList: [],
    accomplishedUsers: [],
    accomplishedUsersCount: 0,
    accomplishedUsersLimit: 80,
    grantAchievement: null,
  }))

  const sortType = ref<SortType>('new')
  const sortItems: SortData[] = [
    {
      value: SORT_TYPE.NEW,
      text: i18n.t('sort.achievement.new'),
    },
    {
      value: SORT_TYPE.OLD,
      text: i18n.t('sort.achievement.old'),
    },
    {
      value: SORT_TYPE.MANY,
      text: i18n.t('sort.achievement.many'),
    },
    {
      value: SORT_TYPE.FEW,
      text: i18n.t('sort.achievement.few'),
    },
  ]
  const page = ref(1)
  const limit = ref(20)
  const offset = ref(0)
  const total = ref(0)

  /** プロフィールに表示するメダルの数 */
  const showingBadgeCount = computed(() => {
    return state.value.achievements.filter(
      (achievement) => achievement.showProfile
    ).length
  })

  /** メダルを最大数セット済みか */
  const isMaxSetBadge = computed(() => {
    if (!state.value.achievements) return false
    return showingBadgeCount.value >= state.value.maxShowingBadgeCount
  })

  const getAchievements = async (_page: number): Promise<UserAchievement[]> => {
    offset.value = _page * limit.value - limit.value

    // HOTFIX: 現状SSRで使用しないのでuseAsyncData未使用（使用するとpending = true, data = null で変えってくる
    // @see https://hikky.atlassian.net/browse/MVK-918
    const response = await achievementRepository.get.getAchievements({
      limit: limit.value,
      offset: offset.value,
      sort: sortType.value,
    })

    if (!response) {
      throw new Error('response is empty.')
    }

    if (!isValueOf(getAchievementsResponse, response)) {
      console.error('response is invalid.')
    }

    page.value = _page
    total.value = response.achievementCount
    state.value.achievements = response.achievements
    state.value.achievementCount = response.achievementCount
    state.value.totalAchievementCount = response.totalAchievementCount
    return response.achievements
  }

  /**
   * 他ユーザのアチーブメント一覧取得
   */
  const getOtherAchievements = async (
    _page: number,
    vketId: string,
    _limit?: number
  ) => {
    offset.value = _page * limit.value - limit.value
    const response = await achievementRepository.get.getOhterAchievements({
      limit: _limit ?? limit.value,
      offset: offset.value,
      vketId,
    })

    if (!response) throw new Error('response is empty.')

    if (!isValueOf(getAchievementsResponse, response)) {
      console.error('An API response is different.')
    }

    page.value = _page
    total.value = response.achievementCount
    state.value.otherAchievementList = response?.achievements || []

    const result: UserAchievement[] | undefined = response?.achievements
    return result
  }

  const getAchievementDetail = async (params: GetAchievementDetailRequest) => {
    const response = await achievementRepository.get.getAchievement(params)

    if (!response) throw new Error('response is empty.')

    if (!isValueOf(getAchievementDetailResponse, response)) {
      console.error('An API response is different.')
    }

    state.value.achievementDetail = response?.achievement || null
    state.value.accomplishedUsersCount =
      response.achievement.accomplishedUsersCount
    state.value.accomplishedUsers = response.achievement.accomplishedUsers
    const result: AchievementDetail | undefined = response?.achievement
    return result
  }

  /**
   * 「(ユーザ用)詳細取得」
   * @param label 対象アチーブメントラベル
   * @param limit accomplished_usersの取得数
   * @param offset accomplished_usersの取得開始位置
   * @returns accomplished_users一覧
   */
  const getAchievementDetailWithInfiniteScroll = async (
    params: GetAchievementDetailRequest
  ) => {
    const response = await achievementRepository.get.getAchievement(params)

    if (!response) throw new Error('response is empty.')

    if (!isValueOf(getAchievementDetailResponse, response)) {
      console.error('An API response is different.')
    }
    state.value.achievementDetail = response?.achievement || null
    state.value.accomplishedUsersCount =
      response.achievement.accomplishedUsersCount
    state.value.accomplishedUsers.push(
      ...response.achievement.accomplishedUsers
    )
    return state.value.accomplishedUsers
  }

  const postGrantAchievement = async (params: PostGrantAchievementRequest) => {
    const response = await achievementRepository.post.postGrantAchievement(
      params
    )

    if (!response) throw new Error('response is empty.')

    if (!isValueOf(postGrantAchievementResponse, response)) {
      console.error('An API response is different.')
    }

    state.value.grantAchievement = response.achievement
  }

  /**
   * 所持しているアチーブメントの情報更新
   **/
  const patchAchievementDetail = async (
    params: PatchAchievementDetailRequest
  ): Promise<PatchOtherAchievementsDetailResponse | null> => {
    const response = await achievementRepository.patch.patchAchievementDetail(
      params
    )

    if (!response) throw new Error('response is empty.')

    if (!isValueOf(patchAchievementDetailResponse, response)) {
      console.error('An API response is different.')
    }
    return response
  }

  /**
   * ソートタイプを更新する
   */
  const updateSortType = (type: SortType) => {
    sortType.value = type
  }

  return {
    state: readonly(state),
    page,
    limit,
    offset,
    showingBadgeCount,
    isMaxSetBadge,
    sortType: readonly(sortType),
    sortItems,
    getAchievements,
    getAchievementDetail,
    getOtherAchievements,
    getAchievementDetailWithInfiniteScroll,
    postGrantAchievement,
    patchAchievementDetail,
    updateSortType,
  }
}

export type AchievementComposable = ReturnType<typeof useAchievement>

export const achievementInjectionKey: InjectionKey<AchievementComposable> =
  Symbol('achievement')
